<template>
  <div>
    <div class="page-title">
      <h3>Already Approved</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li class="active">
            Already Approved
          </li>
        </ol>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div
          v-show="jobType === 'Owner'"
          class="col-md-6"
        >
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                CSV Upload
              </h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12">
                  <div
                    id="csvUpload"
                    class="dropzone"
                  >
                    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
                    <div
                      id="dropzone-message"
                      style="display: none"
                    >
                      <span class="dropzone-title">Drop your file here or click to select</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-t-sm">
                <div class="col-md-2">
                  <button
                    id="uploadFile"
                    class="btn btn-default btn-info"
                    type="button"
                    @click.stop.prevent="uploadFile"
                  >
                    Upload Forms
                  </button>
                </div>
              </div>
              <div class="row m-t-sm">
                <div
                  class="col-md-12"
                  style="font-size: 14px; font-weight: 600"
                >
                  (<span :class="uploadStatus.css">{{ uploadStatus.text }}</span>,
                  New Forms: <span
                    id="success-count"
                    class="text-success"
                  >{{ successCount }}</span>,
                  Updated: <span
                    id="update-count"
                    class="text-info"
                  >{{ updateCount }}</span>,
                  Duplicates: <span
                    id="duplicate-count"
                    class="text-warning"
                  >{{ duplicateCount }}</span>,
                  Errors: <span
                    id="error-count"
                    class="text-danger"
                  >{{ errorCount }}</span>)
                </div>
              </div>
              <div
                v-if="errorText !== ''"
                class="row"
              >
                <div class="col-md-12">
                  <span
                    id="error"
                    class="text-danger"
                  >{{ errorText }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor'"
          class="col-md-6"
        >
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h2 class="panel-title text-center">
                Assign Forms
              </h2>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-xs-4 font-weight-bold">
                  Total: <span
                    id="total-count"
                    class="text-success"
                  >{{ getStats('TOTAL') }}</span>
                </div>
                <div class="col-xs-4 font-weight-bold">
                  Already Approved: <span
                    id="already-count"
                    class="text-info"
                  >{{ getStats('bff3d14d-4ad2-4d5e-a1af-50ba3261cf93') }}</span>
                </div>
                <div class="col-xs-4 font-weight-bold">
                  Approved - Delayed: <span
                    id="delayed-count"
                    class="text-danger"
                  >{{ getStats('d6e18af4-7312-42e7-8508-2961e5c85c48') }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5 col-lg-4">
                  <label class="has-float-label">
                    <input
                      v-model.number="assignCount"
                      name="assignCount"
                      placeholder=" "
                      type="number"
                    >
                    <span class="float-label">Number of Forms <i
                      aria-hidden="true"
                      class="fas fa-asterisk text-danger"
                      style="font-size: 7px; vertical-align: text-top;"
                    /></span>
                  </label>
                </div>
              </div>
              <div class="row">
                <div
                  v-if="jobType === 'Owner'"
                  class="form-group col-md-5"
                >
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="activeBranchId"
                      :allow-empty="false"
                      :class="{ 'placeholder-shown': (!activeBranchId || activeBranchId === '') }"
                      :options="getEnabledBranches"
                      :placeholder="null"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    />
                    <span class="float-label">{{ $Amplify.I18n.get('Branch') }}</span>
                  </label>
                </div>
                <div class="form-group col-md-7">
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="selectedUser"
                      :allow-empty="false"
                      :class="{ 'placeholder-shown': (!selectedUser || selectedUser === '') }"
                      :custom-label="getUserName"
                      :options="_getUsers"
                      :placeholder="null"
                      :show-labels="false"
                      track-by="id"
                    />
                    <span class="float-label">{{ $Amplify.I18n.get('User') }}</span>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-1">
                  <input
                    id="assignFormsButton"
                    class="btn btn-default"
                    type="button"
                    value="Assign Forms"
                    @click.stop.prevent="assignForms"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {API, graphqlOperation} from "aws-amplify";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";
import Dropzone from "dropzone";
import {uploadStatuses} from "@/constants";
import VueMultiselect from "vue-multiselect";
import Papa from "papaparse";

export default {
  name: "AlreadyApproved",
  components: {
    VueMultiselect
  },
  data() {
    return {
      jobType: '',
      activeBranchId: null,
      selectedUser: null,
      assignCount: null,
      fileUploader: null,
      successCount: 0,
      updateCount: 0,
      duplicateCount: 0,
      errorCount: 0,
      uploadStatus: uploadStatuses.success,
      errorText: '',
      assignableForms: []
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getBranches",
      "getStatuses",
      "getTitles",
      "getUsers",
    ]),
    ...mapState([
      'loggedInUser'
    ]),
    getStats() {
      return (type) => {
        if (type === 'TOTAL') {
          return this.assignableForms.length;
        } else {
          return _.filter(this.assignableForms, (form) => {
            return form.status === type;
          }).length;
        }
      };
    },
    getEnabledBranches: function () {
      return _.sortBy(_.filter(this.getBranches, (branch) => {
        return branch.isEnabled;
      }), 'name');
    },
    _getUsers: function () {
      if (this.jobType === 'Owner') {
        return _.sortBy(_.filter(_.concat(this.getTitleUsers('BranchManager'), this.getTitleUsers('Supervisor'), this.getTitleUsers('User')), (user) => {
          return _.includes(user.branchId, this.activeBranchId.number);
        }), 'username');
      } else if (this.jobType === 'Manager') {
        let supervisors = _.filter(this.getTitleUsers('Supervisor'), (user) => {
          return user.supervisor === this.getLoggedInUser.id;
        });
        let supervisorIds = _.map(supervisors, (supervisor) => {
          return supervisor.id;
        });
        let users = _.filter(this.getTitleUsers('User'), (user) => {
          return _.includes(supervisorIds, user.supervisor);
        });
        return _.sortBy(_.concat(supervisors, users), 'username');
      } else if (this.jobType === 'Supervisor') {
        return _.sortBy(_.filter(this.getTitleUsers('User'), (user) => {
          return user.supervisor === this.getLoggedInUser.id;
        }), 'username');
      } else {
        return [];
      }
    }
  },
  watch: {
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        if (newValue.jobTitle && newValue.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === newValue.jobTitle;
          });
          if (jobTitle) {
            this.jobType = jobTitle.access;
          }
          this.activeBranchId = _.filter(this.getBranches, (branch) => {
            return branch.number === newValue.branchId[0];
          })[0];
        }
        this.fetchForms();
      }
    },
    activeBranchId() {
      this.selectedUser = this._getUsers[0];
    }
  },
  created() {
    Dropzone.autoDiscover = false;
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    let options = {
      url: '/',
      method: 'put',
      maxFiles: 1,
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      acceptedFiles: ".csv",
      capture: 'file',
      addRemoveLinks: true,
      parallelUploads: 1,
      uploadMultiple: false,
      header: '',
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
      autoProcessQueue: false
    };

    // Instantiate DropZone
    this.fileUploader = new Dropzone("div#csvUpload", options);

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
        }
        this.activeBranchId = _.filter(this.getBranches, (branch) => {
          return branch.number === this.loggedInUser.branchId[0];
        })[0];
      }
      this.fetchForms();
    }
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    getTitleUsers: function (titleName) {
      let usersList = _.result(_.find(this.getTitles, (title) => {
        return title.access === titleName;
      }), 'users');
      if (usersList) {
        usersList = _.filter(this.getUsers, (user) => {
          return _.includes(usersList, user.id);
        });
        return usersList;
      } else {
        return [];
      }
    },
    getUserName: function (user) {
      let name = _.capitalize(user.firstName);

      if (user.lastName) {
        name += " " + _.capitalize(user.lastName);
      }
      return name;
    },
    async fetchForms() {
      this.assignableForms = [];
      this.fetchFormsByStatusOwner("bff3d14d-4ad2-4d5e-a1af-50ba3261cf93", this.getLoggedInUser.username, null, null); //NOTE: Hardcoded ids = Bad idea
      if (this.jobType === 'Owner') {
        this.fetchFormsByStatus("d6e18af4-7312-42e7-8508-2961e5c85c48", null, null);  //NOTE: Hardcoded ids = Bad idea
      } else {
        this.fetchFormsByStatusOwner("d6e18af4-7312-42e7-8508-2961e5c85c48", this.getLoggedInUser.username, null, null); //NOTE: Hardcoded ids = Bad idea
      }
    },
    async fetchFormsByStatusOwner(status, owner, filter = null, nextPage = null) {
      const {
        data: {
          formsByStatusOwner: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(customQueries.countFormsByStatusOwnerMinimal, {
        status: status,
        owner: {
          eq: owner
        },
        limit: 999,
        filter: filter,
        nextToken: nextPage
      }));
      this.assignableForms = _.concat(this.assignableForms, items);
      nextPage = nextToken;
      if (nextPage) {
        this.fetchFormsByStatusOwner(status, owner, filter, nextPage);
      }
    },
    async fetchFormsByStatus(status, filter = null, nextPage = null) {
      const {
        data: {
          formsByStatus: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(customQueries.countFormsByStatusMinimal, {
        status: status,
        limit: 999,
        filter: filter,
        nextToken: nextPage
      }));
      this.assignableForms = _.concat(this.assignableForms, items);
      nextPage = nextToken;
      if (nextPage) {
        this.fetchFormsByStatus(status, filter, nextPage);
      }
    },
    uploadFile: async function () {
      try {
        const currentTimeEpoch = moment().format('X');
        let files = this.fileUploader.getQueuedFiles();
        if (files && files.length > 0) {
          this.successCount = 0;
          this.updateCount = 0;
          this.duplicateCount = 0;
          this.errorCount = 0;
          this.errorText = '';
          this.uploadStatus = uploadStatuses.progress;
          let updatePromises = [];

          let csv = files[0];
          this.fileUploader.removeAllFiles();
          Papa.parse(csv, {
            header: true,
            dynamicTyping: true,
            worker: true,
            step: (results) => {
              if (results.data && results.data['pancard'] && results.data['pancard'] !== '') {
                let pancard = _.trim(results.data['pancard']);
                let newForm = {
                  pancard: _.toUpper(pancard),
                  status: 'bff3d14d-4ad2-4d5e-a1af-50ba3261cf93',
                  owner: this.getLoggedInUser.username,
                  supervisor: this.getLoggedInUser.username,
                  branchManager: this.getLoggedInUser.username,
                  updatedAtEpoch: currentTimeEpoch
                };
                if (results.data['bank date'] && results.data['bank date'] !== '') {
                  newForm.bankVerificationDate = moment(results.data['bank date'], 'D-MMM-YY').toISOString();
                  newForm.bankVerificationEpoch = moment(results.data['bank date'], 'D-MMM-YY').format('X');
                  let ninetyDayCalc = moment(results.data['bank date'], 'D-MMM-YY').add(90, 'days');
                  newForm.dateNinetyDays = ninetyDayCalc.format('YYYY') + '-' + ninetyDayCalc.format('MM') + '-' + ninetyDayCalc.format('DD');
                  newForm.dateNinetyDaysEpoch = ninetyDayCalc.startOf('day').format('X');
                } else {
                  newForm.bankVerificationDate = moment(currentTimeEpoch, 'X').toISOString();
                  newForm.bankVerificationEpoch = currentTimeEpoch;
                  let ninetyDayCalc = moment(currentTimeEpoch, 'X').add(90, 'days');
                  newForm.dateNinetyDays = ninetyDayCalc.format('YYYY') + '-' + ninetyDayCalc.format('MM') + '-' + ninetyDayCalc.format('DD');
                  newForm.dateNinetyDaysEpoch = ninetyDayCalc.startOf('day').format('X');
                }
                if (results.data['first name'] && results.data['first name'] !== '') {
                  newForm.firstName = _.toUpper(results.data['first name']);
                }
                if (results.data['last name'] && results.data['last name'] !== '') {
                  newForm.lastName = _.toUpper(results.data['last name']);
                }
                if (results.data['city'] && results.data['city'] !== '') {
                  newForm.city = _.toUpper(results.data['city']);
                }
                if (results.data['reference'] && results.data['reference'] !== '') {
                  newForm.referenceNumber = _.toUpper(results.data['reference']);
                }
                if (results.data['number'] && results.data['number'] !== '') {
                  newForm.mobileNumber = _.toUpper(results.data['number']);
                }
                updatePromises.push(this.updateForm(newForm));
              }
            },
            complete: () => {
              Promise.all(updatePromises).then((results) => {
                this.successCount = _.filter(results, (result) => {
                  return result === 'success';
                }).length;
                this.updateCount = _.filter(results, (result) => {
                  return result === 'update';
                }).length;
                this.duplicateCount = _.filter(results, (result) => {
                  return result === 'duplicate';
                }).length;
                this.errorCount = _.filter(results, (result) => {
                  return result === 'error';
                }).length;

                if (this.errorCount > 0) {
                  this.uploadStatus = uploadStatuses.error;
                } else {
                  this.uploadStatus = uploadStatuses.success;
                }
              });
            },
            error: (err) => {
              this.uploadStatus = uploadStatuses.error;
              this.errorText = err;
            }
          })

        }
      } catch (err) {
        this.uploadStatus = uploadStatuses.error;
        this.errorText = err;
      }
    },
    async updateForm(form) {
      try {
        const {
          data: {
            formsByPancardProduct: {items}
          }
        } = await API.graphql(graphqlOperation(customQueries.countFormsByPancardProductMinimal, {
          pancard: form.pancard,
          product: {
            eq: form.product
          },
          limit: 999
        }));
        if (items.length > 0) {
          if (_.includes([
            "1fdca13d-adf6-411a-a002-a33e228d22df",
            "d73d381f-cf14-4388-9ec3-784a763c5431",
            "2d986013-050e-4e8b-8b40-1b959be382c4",
            "7795614b-720c-4581-9309-4f569895ac8d",
            "5e1159c3-e54a-4c66-ae6f-0676522b4cb9",
            "60bf9d14-ea35-44a7-88eb-30ff2eaa1712"], items[0].status)) {
            const currentTimeEpoch = moment().format('X');
            const {
              data: {updateForm}
            } = await API.graphql(graphqlOperation(mutations.updateForm, {
              input: {
                id: items[0].id,
                owner: this.getLoggedInUser.username,
                supervisor: this.getLoggedInUser.username,
                branchManager: this.getLoggedInUser.username,
                remarks: null,
                statusJustifications: null,
                status: form.status,
                bankVerificationDate: form.bankVerificationDate,
                bankVerificationEpoch: form.bankVerificationEpoch,
                dateNinetyDays: form.dateNinetyDays,
                dateNinetyDaysEpoch: form.dateNinetyDaysEpoch,
                referenceNumber: form.referenceNumber || null,
                updatedAtEpoch: currentTimeEpoch,
                expectedVersion: items[0].version
              }
            }));
            this.assignableForms.push(updateForm);
            return "update";
          } else {
            return "duplicate";
          }
        }
        const {
          data: {createForm}
        } = await API.graphql(graphqlOperation(mutations.createForm, {
          input: _.omitBy(form, (field) => {
            return (!field) || field === '';
          })
        }));
        this.assignableForms.push(createForm);
        return "success";
      } catch (e) {
        console.log(e);
        return "error";
      }
    },
    assignForms: async function () {
      if (this.assignableForms.length < 1) {
        this.setMessage('v-notify-error', "Error!", "There are no forms to assign!");
        return;
      }
      if (!this.selectedUser) {
        this.setMessage('v-notify-error', "So Close!", "You must select a user to assign the forms to.");
        return;
      }
      if (!this.assignCount || this.assignCount < 1) {
        this.setMessage('v-notify-error', "Oops!", "Number of forms to assign is set to 0!");
        return;
      }

      if (this.assignCount > this.assignableForms.length) {
        this.assignCount = this.assignableForms.length;
      }
      let formsCopy = _.cloneDeep(this.assignableForms);
      const currentTimeEpoch = moment().format('X');

      for (let i = 0; i < this.assignCount; i++) {
        let currentForm = formsCopy[i];
        let input = {
          id: currentForm.id,
          owner: this.selectedUser.username,
          status: 'bff3d14d-4ad2-4d5e-a1af-50ba3261cf93',
          updatedBy: this.getLoggedInUser.username,
          updatedAtEpoch: currentTimeEpoch,
          expectedVersion: currentForm.version
        };
        if (this.jobType === 'Owner') {
          const selectedUserJobTitle = _.result(_.find(this.getTitles, (title) => {
            return title.id === this.selectedUser.jobTitle
          }), 'access');
          if (selectedUserJobTitle === 'Manager') {
            input.supervisor = this.getLoggedInUser.username;
            input.branchManager = this.getLoggedInUser.username;
          } else if (selectedUserJobTitle === 'Supervisor') {
            input.supervisor = _.result(_.find(this.getUsers, (user) => {
              return user.id === this.selectedUser.supervisor;
            }), 'username', this.getLoggedInUser.username);
            input.branchManager = input.supervisor;
          } else if (selectedUserJobTitle === 'User') {
            let supervisor = _.find(this.getUsers, (user) => {
              return user.id === this.getLoggedInUser.supervisor;
            });
            if (supervisor && supervisor !== '') {
              input.supervisor = supervisor.username;
              input.branchManager = _.result(_.find(this.getUsers, (user) => {
                return user.id === supervisor.supervisor;
              }), 'username', this.getLoggedInUser.username);
            }
          } else {
            this.setMessage('v-notify-error', "Oh No!", "Unable to assign forms to this user.");
            return;
          }
          API.graphql(graphqlOperation(mutations.updateForm, {
            input: input
          }));
          _.remove(formsCopy, (form) => {
            return form.id === currentForm.id;
          });
        } else if (this.jobType === 'Manager') {
          input.supervisor = _.result(_.find(this.getUsers, (user) => {
            return user.id === this.selectedUser.supervisor;
          }), 'username', this.getLoggedInUser.username);
          input.branchManager = this.getLoggedInUser.username;
          API.graphql(graphqlOperation(mutations.updateForm, {
            input: input
          }));
          _.remove(formsCopy, (form) => {
            return form.id === currentForm.id;
          });
        } else if (this.jobType === 'Supervisor') {
          input.supervisor = this.getLoggedInUser.username;
          let supervisorUsername = _.result(_.find(this.getUsers, (user) => {
            return user.id === this.getLoggedInUser.supervisor;
          }), 'username', '');
          if (supervisorUsername && supervisorUsername !== '') {
            input.branchManager = supervisorUsername;
          }
          API.graphql(graphqlOperation(mutations.updateForm, {
            input: input
          }));
          _.remove(formsCopy, (form) => {
            return form.id === currentForm.id;
          });
        }
      }
      this.assignableForms = formsCopy;
      if (this.assignCount < 2) {
        this.setMessage('v-notify-success', "Nice!", this.assignCount + " form has been assigned to " + this.getUserName(this.selectedUser) + ".");
      } else {
        this.setMessage('v-notify-success', "Nice!", this.assignCount + " forms have been assigned to " + this.getUserName(this.selectedUser) + ".");
      }
    }
  }
}
</script>

<style scoped>

</style>